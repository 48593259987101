import { Result, Typography } from "antd";
import React from "react";

const { Paragraph, Text } = Typography;

const AccessDenied: React.FC = () => (
  <Result
    status="error"
    title="No Access"
    subTitle="You are not authorized to access the page requested"
  >
    <div className="desc">
      <Paragraph>
        <Text
          style={{
            fontSize: 16,
          }}
        >
          Please request required access from an administrator.
        </Text>
      </Paragraph>
    </div>
  </Result>
);

export default AccessDenied;
