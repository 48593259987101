import { createSlice } from "@reduxjs/toolkit";
import { ApplicationState } from "../state.model";

const initialState: ApplicationState = {
  list: [],
  gridSource: [],
  selectList: []
};

const applicationReducer = createSlice({
  name: "application",
  initialState,
  reducers: {
    setApplications(state, action) {
      state.list = action.payload;
      state.gridSource = action.payload;
    },
    setApplicationSource(state, action) {
      state.gridSource = action.payload;
    },
    setSelectionList(state, action) {
      state.selectList = action.payload;
    }
  },
});

export const { setApplications, setApplicationSource, setSelectionList } = applicationReducer.actions;

export default applicationReducer.reducer;
