import { Button, Tooltip } from "antd";
import { useSelector } from "react-redux";
import {
  appSelector,
  AppSelectorState,
} from "../../store/selectors/appSelector";
import { AppState } from "../../store/state.model";

export const AuthButton = ({ children, roles, ...props }: any) => {
  const { user } = useSelector<AppState, AppSelectorState>(appSelector);

  const isAuthorized =
    user && user.roles.some((r) => roles.includes(r)) ? true : false;

  if (!isAuthorized) {
    return (
      <Tooltip
        title="You are not authorized to access this functionality"
        key={"noAccess"}
      >
        <Button {...props} disabled>
          {children}
        </Button>
      </Tooltip>
    );
  }
  return <Button {...props}>{children}</Button>;
};
export default AuthButton;
