import { ApiActions, ApiEventType } from '../models/event';
import { UserInvite, UserUpdate } from '../models/user';
import { setApplications } from '../store/reducers/applicationReducer';
import {
  setEvent,
  setLoading,
  setOrganization,
} from '../store/reducers/sharedReducer';
import { setGroups } from '../store/reducers/groupsReducer';
import { setUsers } from '../store/reducers/userReducer';
import { apiService } from './apiService';

export const getAllGroups = (onLoad: boolean) => async (dispatch: any) => {
  try {
    onLoad && dispatch(setLoading(true));
    await apiService.getAllGroups().then((res) => {
      dispatch(setGroups(res));
    });
  } finally {
    onLoad && dispatch(setLoading(false));
  }
};

export const createUpdateGroup =
  (id: string, body: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      if (id && id !== '') {
        await apiService.updateGroup(id, body);
      } else {
        await apiService.createGroup(body);
      }
      dispatch(
        setEvent({
          type: ApiEventType.Success,
          action: ApiActions.GroupUpsert,
          message: `Group ${
            id && id !== '' ? 'updated' : 'created'
          } successfully`,
          setNull: true,
        })
      );
      dispatch(getAllGroups(true));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteGroup = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    await apiService.deleteGroup(id);
    dispatch(
      setEvent({
        type: ApiEventType.Success,
        action: ApiActions.GroupDelete,
        message: `Group deleted successfully`,
        setNull: true,
      })
    );
    dispatch(getAllGroups(true));
  } catch (e: any) {
    dispatch(
      setEvent({
        type: ApiEventType.Error,
        action: ApiActions.GroupDelete,
        message: `${e.message}`,
      })
    );
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};

export const getApplications = () => async (dispatch: any) => {
  try {
    await apiService.getApplications().then((res) => {
      dispatch(setApplications(res));
    });
  } catch (e: any) {
    return console.error(e.message);
  }
};

export const getOrganization = () => async (dispatch: any) => {
  try {
    await apiService.getOrganization().then((res) => {
      res && dispatch(setOrganization(res.id));
    });
  } catch (e: any) {
    return console.error(e.message);
  }
};

export const getUsers =
  (onLoad: boolean, includeDetails: boolean, group?: string) =>
  async (dispatch: any) => {
    try {
      onLoad && dispatch(setLoading(true));
      await apiService.getUsers(includeDetails, group).then((res) => {
        dispatch(setUsers(res));
      });
    } catch (e: any) {
      return console.error(e.message);
    } finally {
      onLoad && dispatch(setLoading(false));
    }
  };

export const inviteUser = (body: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const req: UserInvite = {
      email: body.email,
      groupIds: body.groups,
      roleIds: body.roles,
    };
    await apiService.inviteUser(req);
    dispatch(
      setEvent({
        type: ApiEventType.Success,
        action: ApiActions.UserInvite,
        message: `User invited successfully`,
        setNull: true,
      })
    );
  } catch (e: any) {
    dispatch(
      setEvent({
        type: ApiEventType.Error,
        action: ApiActions.UserInvite,
        message: 'User invite failed with an error. Please try again',
      })
    );
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateUser = (id: string, body: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const req: UserUpdate = {
      email: body.email,
      groupIds: body.groups,
      roleIds: body.roles,
    };
    await apiService.updateUser(id, req);
    dispatch(
      setEvent({
        type: ApiEventType.Success,
        action: ApiActions.UserUpdate,
        message: `User updated successfully`,
        setNull: true,
      })
    );
  } catch (e: any) {
    dispatch(
      setEvent({
        type: ApiEventType.Error,
        action: ApiActions.UserUpdate,
        message: 'User update failed with an error. Please try again',
      })
    );
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteUser = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    await apiService.deleteUser(id);
    dispatch(
      setEvent({
        type: ApiEventType.Success,
        action: ApiActions.UserDelete,
        message: `User deleted successfully`,
        setNull: true,
      })
    );
  } catch (e: any) {
    dispatch(
      setEvent({
        type: ApiEventType.Error,
        action: ApiActions.UserDelete,
        message: `${e.message}`,
      })
    );
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};

export const enableUser = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    await apiService.enableUser(id);
    dispatch(
      setEvent({
        type: ApiEventType.Success,
        action: ApiActions.UserEnabled,
        message: `User account enabled successfully`,
        setNull: true,
      })
    );
  } catch (e: any) {
    dispatch(
      setEvent({
        type: ApiEventType.Error,
        action: ApiActions.UserEnabled,
        message: `${e.message}`,
      })
    );
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};
