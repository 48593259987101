export enum ApiActions{
    GroupUpsert,
    GroupDelete,
    UserInvite,
    UserUpdate,
    UserDelete,
    UserEnabled
}

export enum ApiEventType{
    Success = "success",
    Error = "error"
}

export interface ApiEvent{
    type: ApiEventType,
    action: ApiActions;
    message: string;
    data: any;
}