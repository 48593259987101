import {
  Button,
  Checkbox,
  Modal,
  Select,
  Table,
  TableColumnsType,
  Tooltip,
} from 'antd';
import Search from 'antd/lib/input/Search';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../../models/user';
import { apiService } from '../../services/apiService';
import { deleteUser, getUsers, enableUser } from '../../services/dataService';
import { setLoading } from '../../store/reducers/sharedReducer';
import { setUserSource } from '../../store/reducers/userReducer';
import {
  appSelector,
  AppSelectorState,
} from '../../store/selectors/appSelector';
import { AppState } from '../../store/state.model';
import { UserInviteModal } from './invite';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Role } from '../../models/role';
import { ApiActions, ApiEventType } from '../../models/event';
import { useSearchParams } from 'react-router-dom';
import AuthButton from '../../components/auth/authButton';
import { DeleteOutlined, EditOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import styles from './users.module.scss';
import { debug } from 'console';

const { confirm } = Modal;
const { Option } = Select;

const UserTable = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  // global state
  const { groups, users, usersGrid, applications, event } = useSelector<
    AppState,
    AppSelectorState
  >(appSelector);

  // local state
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [includeDetails, setIncludeDetails] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [onlyExternal, setOnlyExternal] = useState(false);
  const [onlyDisabled, setOnlyDisabled] = useState(false);
  const [selectedGroup, setGroup] = useState('');

  // hooks
  useEffect(() => {
    onSearch(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    const include = searchParams.get('includeDetails') as string;
    setGroup(searchParams.get('group') as string);
    setIncludeDetails(include && include === 'true' ? true : false);
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (
      event != null &&
      event.type === ApiEventType.Success &&
      (event.action === ApiActions.UserUpdate ||
        event.action === ApiActions.UserDelete ||
        event.action === ApiActions.UserEnabled)
    ) {
      loadUsers();
      if (event.action === ApiActions.UserUpdate) setInviteModalVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  useEffect(() => {
    onSearch(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, onlyExternal, onlyDisabled]);

  // methods
  const loadUsers = async () => {
    const include = searchParams.get('includeDetails') as string;
    dispatch<any>(
      getUsers(
        true,
        include && include === 'true' ? true : false,
        searchParams.get('group') as string
      )
    );
  };

  // user events
  const onSearchKeyUp = (event: any) => {
    if (event && event.target) onSearch(event.target.value);
  };

  const onSearch = (inputString: any) => {
    let value = inputString.toLocaleLowerCase();
    setSearchValue(value);
    let filteredUsers = [...users];
    if (value && value !== '') {
      filteredUsers = filteredUsers.filter(
        (a) =>
          a.displayName.toLocaleLowerCase().includes(value) ||
          a.email.toLocaleLowerCase().includes(value)
      );
    }
    if (onlyExternal) {
      filteredUsers = filteredUsers.filter((a) => a.isExternalUser === true);
    }
    
    if (onlyDisabled) {
      filteredUsers = filteredUsers.filter((a) => a.accountEnabled === !onlyDisabled);
    }

    dispatch<any>(setUserSource(filteredUsers));
  };

  const onExternalChange = (e: any) => {
    setOnlyExternal(!onlyExternal);
  };

  const onDisabledChange = (e: any) => {
    setOnlyDisabled(!onlyDisabled);
  };

  const onGroupChange = (e: any) => {
    setSearchParams({
      includeDetails: includeDetails ? 'true' : 'false',
      group: e ? e : '',
    });
  };

  const onIncludeChange = (e: any) => {
    const val = e.target.checked;
    setSearchParams({
      includeDetails: val ? 'true' : 'false',
      group: selectedGroup ? selectedGroup : '',
    });
  };

  const onDelete = async (record: User) => {
    confirm({
      title: 'Do you want to delete this user?',
      icon: <ExclamationCircleOutlined />,
      content: `User : ${record!.email}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        dispatch<any>(deleteUser(record.id));
      },
    });
  };

  const onEnable = async (record: User) => {
    confirm({
      title: 'Do you want to enable this user?',
      icon: <ExclamationCircleOutlined />,
      content: `User : ${record!.email}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        dispatch<any>(enableUser(record.id));
      },
    });
  };

  const onToggleInviteModal = () => setInviteModalVisible((p) => !p);

  const onInvite = async () => {
    setSelectedUser(null);
    onToggleInviteModal();
  };

  const onEdit = async (record: User | null) => {
    setSelectedUser(null);
    dispatch<any>(setLoading(true));
    await apiService.getUser(record?.id!).then((res) => {
      dispatch<any>(setLoading(false));
      setSelectedUser(res);
      onToggleInviteModal();
    });
  };

  const getRoles = (record: User) => {
    if (
      record &&
      record.appRoleAssignments &&
      record.appRoleAssignments.length > 0 &&
      applications &&
      applications.length > 0
    ) {
      let roles: string[] = [];
      record.appRoleAssignments.forEach((x) => {
        const app = applications.filter(
          (a) =>
            a.appRoles.filter((a) => a.id === x.appRoleId) != null &&
            a.appRoles.filter((a) => a.id === x.appRoleId).length > 0
        );
        if (app && app[0] && app[0].appRoles && app[0].appRoles.length > 0) {
          const role = app[0].appRoles.filter((a) => a.id === x.appRoleId);

          if (role && role[0]) {
            roles.push(`${role[0].displayName} (${role[0].description})`);
          }
        }
      });

      return roles.join(', ');
    }

    return '';
  };

  // ui helpers
  const columns: TableColumnsType<User> = [
    {
      title: 'Display Name',
      dataIndex: 'displayName',
      width: 195,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 195,
      render: (_, record) => {
        return (
          <span>
            {record.givenName} {record.surname}
          </span>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 195,
    },
    {
      title: 'Groups',
      dataIndex: 'groups',
      width: 195,
      render: (_, record) => {
        return (
          <span>
            {record.memberOf != null && record.memberOf.length > 0
              ? record.memberOf.map((a) => a.description).join(', ')
              : ''}
          </span>
        );
      },
    },
    {
      title: 'Roles',
      width: 195,
      dataIndex: 'roles',
      render: (_, record) => {
        return <span>{getRoles(record)}</span>;
      },
    },
    {
      title: 'Status',
      width: 100,
      dataIndex: 'accountEnabled',
      render: (_, record) => {
        return <span>{ record.accountEnabled ? 'Enabled' : 'Disabled'}</span>;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 120,
      render: (_, record) => {
        return (
          <span>
            <Tooltip placement='topLeft' title={`Update user ${record.email}`}>
              <Button
                shape='circle'
                icon={<EditOutlined />}
                size='small'
                onClick={() => onEdit(record)}
                key='edit'
              />
            </Tooltip>

            <Tooltip placement='topLeft' title={`Delete user ${record.email}`}>
              <AuthButton
                roles={[Role.SuperAdmin, Role.SupportAdmin]}
                danger
                style={{
                  marginLeft: 8,
                }}
                shape='circle'
                icon={<DeleteOutlined />}
                size='small'
                key='delete'
                onClick={() => onDelete(record)}
              />
            </Tooltip>

            {record.accountEnabled === false && 
              <Tooltip placement='topLeft' title={`Enable user ${record.email}`}>
                <AuthButton
                  style={{
                    marginLeft: 8,
                  }}
                  roles={[Role.SuperAdmin, Role.SupportAdmin]}
                  shape='circle'
                  icon={<CheckOutlined />}
                  size='small'
                  onClick={() => onEnable(record)}
                  key='enable'
                />
              </Tooltip>
            }
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles.filtersContainer}>
        <Select
          showSearch
          allowClear
          placeholder='Filter by group'
          optionFilterProp='children'
          onChange={(val) => onGroupChange(val)}
          style={{
            width: 220,
            marginRight: '16px',
          }}
          value={selectedGroup || undefined}
          className={styles.spaced}
        >
          {groups.map((g) => (
            <Option key={g.id}>
              {g.description} ({g.displayName})
            </Option>
          ))}
        </Select>
        <Checkbox
          onChange={(e) => onIncludeChange(e)}
          checked={includeDetails}
          className={styles.spaced}
        >
          Include Groups & Roles
        </Checkbox>

        <Checkbox
          onChange={(e) => onExternalChange(e)}
          className={styles.spaced}
        >
          External Users
        </Checkbox>

        <Checkbox
          onChange={(e) => onDisabledChange(e)}
          className={styles.spaced}
        >
          Disabled Users
        </Checkbox>

        <div style={{ marginLeft: 'auto' }}>
          <Search
            placeholder='Display Name, Email'
            onSearch={(val) => onSearch(val)}
            onKeyUp={(val) => onSearchKeyUp(val)}
            style={{ width: 300, marginRight: '16px' }}
            className={styles.spaced}
          />
          <Button
            onClick={onInvite}
            type='primary'
            icon={<PlusOutlined />}
            className={styles.spaced}
          >
            Invite User
          </Button>
        </div>
      </div>
      <div className={styles.tableContent}>
        <Table
          dataSource={usersGrid}
          columns={columns}
          rowKey={(record) => record.id}
          sticky={{ offsetHeader: 0.5 }}
          pagination={{
            hideOnSinglePage: true,
            position: ['bottomCenter'],
          }}
          scroll={{ x: 'max-content' }}
        />
      </div>
      <UserInviteModal
        show={inviteModalVisible}
        toggleShow={onToggleInviteModal}
        formValue={selectedUser}
      />
    </>
  );
};

export default UserTable;
