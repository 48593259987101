import applicationReducer from "./applicationReducer";
import sharedReducer from "./sharedReducer";
import groupReducer from "./groupsReducer";
import userReducer from "./userReducer";

const rootReducer = () => ({
    shared: sharedReducer,
    group: groupReducer,
    user: userReducer,
    application: applicationReducer
});

export default rootReducer;