import { apiConfig } from './../configs/api';
import { Group } from '../models/group';
import { store } from '../store/store';
import { User } from '../models/user';
import { Application } from '../models/application';
import { asyncLogin } from './authService';

export enum HttpMethods {
  GET,
  POST,
  PUT,
  DELETE,
}

async function request(
  url: string,
  method: HttpMethods,
  payload: any | null = null
): Promise<any> {
  try {
    var httpMethod = HttpMethods[method];
    const requestOptions = {
      method: httpMethod,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getState().shared.token}`,
      },
      body: payload !== null ? JSON.stringify(payload) : null,
    };

    const response = await fetch(url, requestOptions);
    if (response.status !== 200) {
      if (response.status === 401) {
        asyncLogin();
      }
      console.error(response);
      throw new Error(`Request failed with status code ${response.status}`);
    }

    return response.json();
  } catch (e) {
    throw new Error(`Failed to fetch from ${url}: ${e}`);
  }
}

export const apiService = {
  async getAllGroups(): Promise<Group[]> {
    const url = `${apiConfig.url}/Group`;
    return request(url, HttpMethods.GET, null);
  },
  async createGroup(body: any): Promise<Group> {
    const url = `${apiConfig.url}/Group`;
    return request(url, HttpMethods.POST, body);
  },
  async updateGroup(id: string, body: any): Promise<Group> {
    const url = `${apiConfig.url}/Group/${id}`;
    return request(url, HttpMethods.PUT, body);
  },
  async deleteGroup(id: string): Promise<any> {
    const url = `${apiConfig.url}/Group/${id}`;
    return request(url, HttpMethods.DELETE, null);
  },
  async getUsers(include: boolean, group?: string): Promise<User> {
    const url = `${apiConfig.url}/UserAccout?includeDetails=${include}&group=${
      group ? group : ''
    }`;
    return request(url, HttpMethods.GET, null);
  },
  async getUser(id: string): Promise<User> {
    const url = `${apiConfig.url}/UserAccout/id/${id}`;
    return request(url, HttpMethods.GET, null);
  },
  async deleteUser(id: string): Promise<any> {
    const url = `${apiConfig.url}/UserAccout/${id}`;
    return request(url, HttpMethods.DELETE, null);
  },
  async inviteUser(body: any): Promise<any> {
    const url = `${apiConfig.url}/UserAccout/invite`;
    return request(url, HttpMethods.POST, body);
  },
  async updateUser(id: string, body: any): Promise<any> {
    const url = `${apiConfig.url}/UserAccout/${id}`;
    return request(url, HttpMethods.PUT, body);
  },
  async getApplications(): Promise<Application[]> {
    const url = `${apiConfig.url}/Application`;
    return request(url, HttpMethods.GET, null);
  },
  async getOrganization(): Promise<any> {
    const url = `${apiConfig.url}/Application/organization`;
    return request(url, HttpMethods.GET, null);
  },
  async enableUser(id: string): Promise<any> {
    const url = `${apiConfig.url}/UserAccout/enable-user/${id}`;
    return request(url, HttpMethods.POST, null);
  },
};
