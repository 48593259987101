import { Skeleton } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { asyncLogin } from '../../services/authService';
import { getOrganization } from '../../services/dataService';
import {
  appSelector,
  AppSelectorState,
} from '../../store/selectors/appSelector';
import { AppState } from '../../store/state.model';

const AuthGuard = ({ children, roles }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, token } = useSelector<AppState, AppSelectorState>(appSelector);

  useEffect(() => {
    dispatch<any>(asyncLogin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    token !== null &&
      user &&
      user.roles.some((r) => roles.includes(r)) &&
      dispatch<any>(getOrganization());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (token === null) {
    return (
      <Skeleton className='padded' avatar paragraph={{ rows: 10 }}></Skeleton>
    );
  }

  const isAuthorized =
    user && user.roles.some((r) => roles.includes(r)) ? true : false;

  if (!isAuthorized && window.location.hash !== '#/401') {
    navigate('/401');
    return;
  }

  return children;
};

export default AuthGuard;
