import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "../state.model";

const initialState: UserState = {
  list: [],
  userSource: [],
};

const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers(state, action) {
      state.list = action.payload;
    },
    setUserSource(state, action) {
      state.userSource = action.payload;
    }
  },
});

export const { setUsers, setUserSource } = userReducer.actions;

export default userReducer.reducer;
