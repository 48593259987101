import ReactDOM from 'react-dom/client';
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import 'antd/dist/antd.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import GroupTable from './views/groups';
import Main from './components/layout/main';
import { Provider } from 'react-redux';
import { store } from './store/store';
import UserTable from './views/users';
import AccessDenied from './views/auth/accessDenied';
import AuthGuard from './components/auth/authGuard';
import { Role } from './models/role';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <Router>
      <AuthGuard roles={[Role.User, Role.Admin, Role.SuperAdmin]}>
        <Main>
          <Routes>
            <Route path='/' element={<Navigate to='/users' />} />
            <Route path='/groups' element={<GroupTable />} />
            <Route path='/users' element={<UserTable />} />
            <Route path='/401' element={<AccessDenied />} />
          </Routes>
        </Main>
      </AuthGuard>
    </Router>
  </Provider>
);

reportWebVitals();
