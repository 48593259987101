
export interface Config {
    url: string;
}

const isDevelopment = (): boolean => {
    return (
      document.location.origin.includes('localhost') ||
      document.location.origin.includes('test')
    );
  };
  
  
const getApiUrl = (): string => {
if (isDevelopment()) {
    return '-test';
}
 return '';
};

const apiConfig: Config = {
    url: `https://craftusermanagement${getApiUrl()}.azurewebsites.net/api`
};

export {apiConfig};