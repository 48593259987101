import { Layout, notification, Spin } from 'antd';
import Header from './header';
import Sidenav from './sidenav';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AppState } from '../../store/state.model';
import {
  appSelector,
  AppSelectorState,
} from '../../store/selectors/appSelector';
import { ApiEventType } from '../../models/event';
import { setEvent } from '../../store/reducers/sharedReducer';
const { Content } = Layout;

const Main = ({ children }: any) => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  const { event, loading } = useSelector<AppState, AppSelectorState>(
    appSelector
  );

  useEffect(() => {
    if (event !== null) {
      notification[event.type]({
        message:
          event.type === ApiEventType.Success
            ? 'Success'
            : 'Something went wrong',
        description: event.message,
      });
      dispatch<any>(setEvent(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  return (
    <Layout style={{ height: '100vh', margin: 0, padding: 0 }}>
      {contextHolder}
      <Header />
      <Layout
        className='ant-layout-has-sider'
        style={{ padding: '48px 0 0 0', height: '100%' }}
      >
        <Sidenav />

        <Layout
          style={{
            padding: '0 24px 24px 24px',
            overflow: 'scroll',
          }}
        >
          <Content
            className='site-layout-background'
            style={{
              padding: '16px 0',
              margin: 0,
              minHeight: 280,
              background: '#f0f2f5',
              height: '100%',
            }}
          >
            <Spin spinning={loading}>{children}</Spin>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Main;
