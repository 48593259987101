import { Button, Form, Input, Modal, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthButton from '../../../components/auth/authButton';
import { ApiActions } from '../../../models/event';
import { Group } from '../../../models/group';
import { Role } from '../../../models/role';
import { createUpdateGroup } from '../../../services/dataService';
import {
  appSelector,
  AppSelectorState,
} from '../../../store/selectors/appSelector';
import { AppState } from '../../../store/state.model';

export interface NewGroupProps {
  show: boolean;
  toggleShow: any;
  formValue: Group | null;
}

export const NewGroupModal = (props: NewGroupProps) => {
  const { show, toggleShow, formValue } = props;
  const [isUpdate, setUpdate] = useState(false);
  const [form] = useForm();
  const dispatch = useDispatch();
  const { event, loading } = useSelector<AppState, AppSelectorState>(
    appSelector
  );

  // hooks
  useEffect(() => {
    setUpdate(false);
    if (show) {
      form.resetFields();

      if (formValue) {
        form.setFieldsValue({
          objectId: formValue.id,
          groupId: formValue.displayName,
          groupName: formValue.description,
        });
        setUpdate(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (event != null && event.action === ApiActions.GroupUpsert) {
      toggleShow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const submitForm = async () => {
    try {
      form.submit();
      await form.validateFields();
    } catch (errors) {
      return;
    }
    const param = form.getFieldsValue();
    dispatch<any>(createUpdateGroup(param.objectId, param));
  };

  return (
    <>
      <Modal
        title={isUpdate ? 'Update Group' : 'Create Group'}
        visible={show}
        onCancel={toggleShow}
        footer={[
          <Button key='back' onClick={toggleShow}>
            Cancel
          </Button>,
          <AuthButton
            key='submit'
            type='primary'
            onClick={submitForm}
            roles={[Role.Admin, Role.SuperAdmin]}
            style={{ marginLeft: 5 }}
          >
            Submit
          </AuthButton>,
        ]}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            <Form.Item label='Object Id' name='objectId' hidden={!isUpdate}>
              <Input placeholder='Azure B2C Id' disabled={true} />
            </Form.Item>

            <Form.Item
              label='Group Id'
              name='groupId'
              rules={[
                {
                  required: true,
                  message: 'Group id is required',
                },
                {
                  pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                  message: 'No space or special characters allowed',
                },
              ]}
            >
              <Input placeholder='Salesforce Account id' />
            </Form.Item>
            <Form.Item
              label='Group Name'
              name='groupName'
              rules={[
                {
                  required: true,
                  message: 'Group name is required',
                },
              ]}
            >
              <Input placeholder='Salesforce Account name' />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
