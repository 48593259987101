import { Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { GroupOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const getItem = (label: string, key: string, icon: any, children: any) => {
  return {
    key,
    icon,
    children,
    label,
  };
};

const items = [
  getItem('Users', '/users', <UserOutlined />, null),
  getItem('Groups', '/groups', <GroupOutlined />, null),
];

const Sidenav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const onClick = (e: any) => {
    if (e && e.key) {
      navigate(e.key, { replace: true });
    }
  };

  return (
    <Sider
      width={200}
      className='site-layout-background'
      style={{
        height: '100%',
      }}
    >
      <Menu
        selectedKeys={[pathname]}
        mode='inline'
        onClick={onClick}
        style={{
          height: '100%',
          borderRight: 0,
        }}
        items={items}
      />
    </Sider>
  );
};

export default Sidenav;
