import { Application } from "../../models/application";
import { LoggedUser } from "../../models/auth";
import { ApiEvent } from "../../models/event";
import { Group } from "../../models/group";
import {  User } from "../../models/user";
import { AppState } from "../state.model";

export interface AppSelectorState {
  groups: Group[];
  groupsGrid: Group[],
  groupLoading: boolean;
  token: string | null;
  user: LoggedUser | null;
  users: User[];
  usersGrid: User[];
  applications: Application[];
  applicationsGrid: Application[];
  appSelectionList: Application[];
  loading: boolean;
  event: ApiEvent | null;
  organization: string | null;
}

export const appSelector = ({
  shared,
  group,
  user,
  application
}: AppState): AppSelectorState => ({
  groups: group.list,
  groupsGrid: group.gridSource,
  groupLoading: group.loading,
  token: shared.token,
  user: shared.user,
  users: user.list,
  usersGrid: user.userSource,
  applications: application.list,
  applicationsGrid: application.gridSource,
  appSelectionList: application.selectList,
  loading: shared.loading,
  event: shared.event,
  organization: shared.organization
});
