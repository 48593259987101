import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/index"

const preloadedState = {};
export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(),
  reducer: rootReducer(),
  preloadedState
});

