import { createSlice } from "@reduxjs/toolkit";
import { GroupState } from "../state.model";

const initialState: GroupState = {
  list: [],
  gridSource: [],
  loading: false
};

const groupReducer = createSlice({
  name: "group",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setGroups(state, action) {
      state.list = action.payload;
    },
    setGroupSource(state, action) {
      state.gridSource = action.payload;
    }
  },
});

export const { setGroups, setGroupSource } = groupReducer.actions;

export default groupReducer.reducer;
