import { Button, Table, TableColumnsType, Modal, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Group } from '../../models/group';
import { deleteGroup, getAllGroups } from '../../services/dataService';
import { setGroupSource } from '../../store/reducers/groupsReducer';
import {
  appSelector,
  AppSelectorState,
} from '../../store/selectors/appSelector';
import { AppState } from '../../store/state.model';
import { NewGroupModal } from './newGroup';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Role } from '../../models/role';
import { useNavigate } from 'react-router-dom';
import {
  DeleteOutlined,
  UserSwitchOutlined,
  EditOutlined,
} from '@ant-design/icons';
import AuthButton from '../../components/auth/authButton';
import styles from './groups.module.scss';
const { confirm } = Modal;

const GroupTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // global state
  const { groups, groupsGrid } = useSelector<AppState, AppSelectorState>(
    appSelector
  );

  // local state
  const [groupModalVisible, setGroupModalVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [searchValue, setSearchValue] = useState('');

  // hooks
  useEffect(() => {
    onSearch(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  useEffect(() => {
    loadGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // methods
  const loadGroups = async () => {
    dispatch<any>(getAllGroups(true));
  };

  // user events

  const onSearchKeyUp = (event: any) => {
    if (event && event.target) onSearch(event.target.value);
  };

  const onSearch = (inputString: string) => {
    let value = inputString.toLocaleLowerCase();
    setSearchValue(value);
    let filteredGroups = [...groups];
    if (value && value !== '') {
      value = value.trim();
      filteredGroups = filteredGroups.filter(
        (a) =>
          a.displayName.toLocaleLowerCase().includes(value) ||
          a.description.toLocaleLowerCase().includes(value)
      );
    }
    dispatch<any>(setGroupSource(filteredGroups));
  };

  const onToggleGroupModal = () => setGroupModalVisible((p) => !p);

  const onDelete = (record: Group | null) => {
    confirm({
      title: 'Do you want to delete this group?',
      icon: <ExclamationCircleOutlined />,
      content: `Group : ${record!.description}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        dispatch<any>(deleteGroup(record?.id!));
      },
    });
  };

  const onCreateUpdate = (record: Group | null) => {
    setSelectedGroup(record);
    onToggleGroupModal();
  };

  const onViewUsers = (record: Group | null) => {
    if (record) {
      navigate(`/users?group=${record.id}&includeDetails=true`);
    }
  };
  // ui helpers
  const columns: TableColumnsType<Group> = [
    {
      title: 'Group Id',
      dataIndex: 'displayName',
      width: 300,
    },
    {
      title: 'Group Name',
      dataIndex: 'description',
      width: 400,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 200,
      render: (_, record) => {
        return (
          <span>
            <Tooltip
              placement='topLeft'
              title={`Update group ${record.description}`}
            >
              <Button
                shape='circle'
                icon={<EditOutlined />}
                size='small'
                onClick={() => onCreateUpdate(record)}
              />
            </Tooltip>

            <Tooltip
              placement='topLeft'
              title={`Delete group ${record.description}`}
            >
              <AuthButton
                roles={[Role.SuperAdmin]}
                danger
                style={{
                  marginLeft: 8,
                }}
                shape='circle'
                icon={<DeleteOutlined />}
                size='small'
                onClick={() => onDelete(record)}
              />
            </Tooltip>
            <Tooltip
              placement='topLeft'
              title={`View users for group ${record.description}`}
            >
              <Button
                style={{
                  marginLeft: 8,
                  color: '#1890ff',
                }}
                shape='circle'
                icon={<UserSwitchOutlined />}
                size='small'
                onClick={() => onViewUsers(record)}
              />
            </Tooltip>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles.filtersContainer}>
        <Search
          placeholder='Group Id, Group Name'
          onSearch={(val) => onSearch(val)}
          onKeyUp={(val) => onSearchKeyUp(val)}
          style={{ width: 300, margin: '16px 0' }}
        />{' '}
        <Button
          onClick={(e) => onCreateUpdate(null)}
          style={{ float: 'right', margin: '16px 0' }}
          type='primary'
          icon={<PlusOutlined />}
        >
          New Group
        </Button>
      </div>
      <div className={styles.tableContent}>
        <Table
          dataSource={groupsGrid}
          columns={columns}
          rowKey={(record) => record.id}
          sticky={{ offsetHeader: 0.5 }}
          scroll={{ x: 'max-content' }}
        />
        <NewGroupModal
          show={groupModalVisible}
          toggleShow={onToggleGroupModal}
          formValue={selectedGroup}
        />
      </div>
    </>
  );
};

export default GroupTable;
